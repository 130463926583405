/*  bootstrap > colors
    ========================================================================== */
	$green-light: #c5ddb6;
	$green: #71a84e;

	$blue-light: #cbeaef;
	$blue: #57acb5;
	$blue-dark: #1671ba;

	$beige-light: #ffeedc;
	$beige: #cca372;

	$pink: #ef5b97;
	$orange: #f79423;

	$black: #191919;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary: $pink;
    $secondary: $orange;

/*  bootstrap > body
    ========================================================================== */
	$body-bg: $blue-light;
	$body-color: $black;

	$link-color: $primary;
	$link-decoration: underline;
	$link-hover-color: darken($link-color, 50%);

/*  bootstrap > font
    ========================================================================== */
	@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Source+Sans+3:ital,wght@0,400;0,700;1,400;1,700&display=swap');

    $font-family-primary: 'Source Sans 3', sans-serif;
	$font-family-secondary: 'Lilita One', sans-serif;

	$font-awesome: "Font Awesome 5 Pro";
    $font-family-base: $font-family-primary;

    $headings-font-family: $font-family-secondary;
    $headings-font-weight: 400;
	$headings-color: $blue;

    $h1-font-size: 2.25rem;    /* 36 */
    $h2-font-size: 1.875rem;   /* 30 */
    $h3-font-size: 1.5rem;     /* 24 */
    $h4-font-size: 1.25rem;    /* 20 */
    $h5-font-size: 1.125rem;   /* 18 */
    $h6-font-size: 1rem;       /* 16 */


	// matrix-colors
	$matrix-color-primary: $primary;
	$matrix-color-secondary: $secondary;

/*  owl-carousel
    ========================================================================== */
    $carousel-nav-button-width: 50px;
    $carousel-nav-button-font_size: 1.5rem;
.ui-widget.ui-widget-content {
    border: unset;
    box-shadow: 0 3px 6px rgba($black, 0.29);
    padding: 1rem;
    width: 100%;
    max-width: calc(100% - (30px * 4) );

    @include media-breakpoint-up(sm) {
        max-width: 480px;
        padding: 1rem 1.25rem;
    }

    @include media-breakpoint-up(md) {
        max-width: 660px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 442px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 532px;
    }
}

.ui-datepicker .ui-datepicker-header,
.ui-datepicker table {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
}

.ui-datepicker {
    font-family: $font-family-base;

    table {
        font-size: 1em;
    }
}

.ui-datepicker .ui-datepicker-header {
    padding: 0;
    border: none;
    background: unset;
    text-transform: capitalize;
    font-weight: 400;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer;

    span {
        background: unset;
        text-indent: 0;
        height: 20px;
        color: $pink;

        &::before {
            font-family: $font-awesome;
            font-weight: 400;
        }
    }

    &-hover {
        background: unset;
        border: unset;
        top: 2px;

        span {
            color: $orange;
        }
    } 
}

.ui-datepicker-prev {
    span::before {
        content: '\f137';
    }

    &-hover {
        left: 2px;
    }
}


.ui-datepicker-next {
    span::before {
        content: '\f138';
    }

    &-hover {
        right: 2px;
    }
}

.ui-datepicker th {
    font-weight: 400;
    width: calc(100% / 7);
    text-transform: capitalize;
}

.ui-state-default {
    text-align: center;
    border: none;
    background: none;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 6px;
    
    @include media-breakpoint-up(sm) {
        margin: 8px;
    }

    &.ui-state-active,
    &.ui-state-hover {
        background-color: $orange;
    }

    &.ui-state-highlight {
        background-color: $pink;
        color: $white;
    }
}

.ui-datepicker-calendar thead tr {
    border-bottom: 1px solid #ddd;
}
.accordion-list-item {
    border-top-color: $blue;
    
    &:last-child {
        border-bottom-color: $blue;
    }

    &-header {
        button {
            font-weight: 400;
            color: $blue;
            padding-top: 20px;
            padding-bottom: 20px;

            &::after {
                content: '\e98b';
            }

            &[aria-expanded="true"]::after {
                content: '\e990';
            }
        }
    }
}
.list {
	.list-item {
		>.title {
			display: block;
			font-weight: bold;
			margin-bottom: 8px;
		}

		&.list-item-with-title {
			>.title {
				margin-bottom: 0px;
			}
		}
	}

	&.list-grid {
		@include make_row();

		padding-left: 0 !important;
		margin: 0 -15px;

		.list-item {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 15px 0;

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}
	}
}

ol.custom-list {
	.list-item {
		&::before {
			content: "\f00c" !important;
			font-size: 1rem !important;
			color: $green !important;
			font-weight: 700 !important;
		}
	}
}

ul.custom-list-check {
	list-style: none;
	padding: 0 !important;
	margin: 0;

	.sub-list-item {
		padding-left: 1.5rem;
		position: relative;
		line-height: 1.15 !important;
		margin: 0 0 8px 0 !important;

		&::before {
			content: '';
			background-image: url('/images/check.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
			height: 16px;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.list.list-icons {
	.list-item {
		.icon {
			position: static !important;
		}
	}
}


// kenmerken icons list
.custom-list-icons {
	color: $primary;
	display: flex;
	gap: 0.875rem;
	margin: 0 0 10px;

	.list-item {
		padding: 3px 10px;
		border-radius: 50px;
		line-height: normal;
		background-color: $white;
		color: $secondary;
		display: flex;
		align-items: center;
		gap: .25rem;
		border: 1px solid #cecece;
		transition: .2s;

		.icon {
			position: static !important;
			font-size: 15px;

			i {
				font-weight: 300;
			}
		}

		.title {
			font-weight: 400;
			font-size: $small-font-size;
			margin-bottom: 0;
		}

		&:hover {
			background-color: $secondary;
			border-color: $secondary;
			color: $white;

			.hover-label {
				bottom: calc(100% + 1em);
				z-index: 1;
				visibility: visible;
				opacity: 1;
			}
		}

		.hover-label {
			position: absolute;
			bottom: 100%;
			left: 50%;
			width: max-content;
			transform: translateX(-50%);
			background-color: $black;
			color: #fff;
			padding: 4px 15px;
			border-radius: 4px;
			filter: drop-shadow(2px 2px 2px rgba(#000, 0.4));
			z-index: -1;
			visibility: hidden;
			opacity: 0;
			transition: 0.2s;
			font-size: $small-font-size;
			text-transform: capitalize;

			@include media-breakpoint-down(lg) {
				max-width: 110px;
				padding: 4px 10px;
				text-overflow: ellipsis;
				overflow-x: clip;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				bottom: -8px;
				left: 50%;
				transform: translateX(-50%);
				border-left: 10px solid transparent;
				border-top: 10px solid $black;
				border-right: 10px solid transparent;
			}
		}
	}
}

&.accommodation_search_book,
&.accommodation_detail {
	.content {
		padding-top: 3rem;
	}
}

.accommodation-list-item {
	.characteristics-list-with-icon-content {
		.list-item {
			padding: 4px;
			&.list-item-with-title {
				padding: 4px 10px;
			}
		}
	}
}

&.accommodation_category_detail,
&.accommodation_detail {
	.accommodation_detail {
		.extra-info {
			&.full-width {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.tommy-info {
			@include make-col-ready();
			@include make-col(12);
		}

		.tommy {
			margin-bottom: 3rem;

			.w3-vue-booking-wrapper {
				.w3-filter-collection {
					margin-bottom: 1rem !important;

					.filters {
						justify-content: flex-start !important;
					}
				}
			}
		}
	}
}

&.accommodation_detail {

	section section {
		.container {
			padding: 0;
		}
	}

	.info {
		.list-item {
			cursor: default;
		}
	}

	.content-section {
		h1, h2, h3, h4, h5, h6 {
			color: $blue;
		}
	}

	.desc,
	.intro-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 10;
		-webkit-box-orient: vertical;
		line-clamp: 10;

		&.active {
			-webkit-line-clamp: 100;
			line-clamp: 100;
		}
	}

	.desc {
		@include media-breakpoint-up(lg) {
			display: -webkit-box !important;
		}
	}

	.intro-desc {
		@include media-breakpoint-down(md) {
			display: -webkit-box !important;
		}
	}

	.read-more {
		position: relative;
		margin-top: 10px;
		padding-top: 3px;
		
		.btn {
			font-size: 18px;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 80px;
			background-image: linear-gradient(rgba(#FFF, 0), rgba($body-bg, 1) 80%);
			position: absolute;
			bottom: 100%;
		}

		&.active {
			&::before {
				display: none;
			}
		}
	}
}
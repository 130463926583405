/*  spacer
    ========================================================================== */
.spacer {
	background-color: $yellow;
	.container-default {
		position: relative;
		.item {
			&:before {
				content: "";
				z-index: 10;
				position: absolute;
				top: 0;
				right: 0;
				height: 8vh;
				border-top: 8vh solid $white;
				border-left: 100vw solid transparent;
				border-right: 0 solid transparent;
			}
			&:after {
				content: "";
				z-index: 10;
				position: absolute;
				right: 0;
				bottom: 0;
				height: 8vh;
				border-bottom: 8vh solid $white;
				border-left: 100vw solid transparent;
				border-right: 0 solid transparent;
			}
			.owl-caption {
				z-index: 50;
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: 30px;
				max-width: 940px;
				margin: 0 auto;
				padding: 30px;
				color: $white;
				text-align: center;
				&:before {
					content: "";
					z-index: -1;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $green;
					transform: skewX(-2deg) skewY(-2deg);
				}
				.owl-title {
					text-shadow: none;
					font-size: 1.125rem;
					@include media-breakpoint-up(md) {
						font-size: 1.5rem;
					}
				}
				.owl-description {
					text-shadow: none;
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.owl-carousel.large {
		min-height: $carousel-min_height;
		.item {
			min-height: $carousel-min_height;
		}
	}
}

// accommodation-carousel
.owl-carousel.accommodation-carousel {
	min-height: auto;
	.owl-nav {
		height: 30px;
		margin: 0;
		transform: translateY(50%);
		.owl-next,
		.owl-prev {
			height: 30px;
			line-height: 34px;
			font-size: 20px;
		}
	}
}

// accommodation-gallery
.owl-carousel.accommodation-gallery {
	.item {
		border-radius: 20px;
	}
}